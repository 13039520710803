// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}

.error {
  color: red;
  margin-top: 5px;
  font-size: 0.9em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Users/UserFormPage.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".form-group {\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.form-group label {\r\n  display: block;\r\n  margin-bottom: 5px;\r\n}\r\n\r\n.form-group input,\r\n.form-group select {\r\n  width: 100%;\r\n  padding: 8px;\r\n  box-sizing: border-box;\r\n}\r\n\r\n.error {\r\n  color: red;\r\n  margin-top: 5px;\r\n  font-size: 0.9em;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
