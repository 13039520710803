import { UserManager, WebStorageStateStore } from "oidc-client";

const oidcConfig = {
  authority: "https://authgateway3-dev.entiam.uhg.com",
  client_id: "Reg3Dev_stepwise_spa",
  response_type: "code",
  code_challenge_method: "S256",
  redirect_uri: "https://docsearch.stepwise.optum.com/callback",
  scope: "openid profile address email phone",
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  extraQueryParams: {
    acr_values: "R3_AAL1_MS-AD-Kerberos_Only",
  },
};

const userManager = new UserManager(oidcConfig);

export function login() {
  return userManager.signinRedirect();
}

export function getUser() {
  return userManager.getUser();
}

export function handleCallback() {
  return userManager.signinRedirectCallback();
}
