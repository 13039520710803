import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleCallback } from "../../services/auth";

const Callback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    handleCallback()
      .then(() => {
        navigate("/");
      })
      .catch((err) => {
        console.error("Error handling callback:", err);
        navigate("/error");
      });
  }, [navigate]);
};

export default Callback;
