import React from "react";
import "./Header.css";
import logo from "../../assets/images/optum-logo.png";

const Header = ({ user, setProfileOpen, selectedOrg, setSelectedOrg, organizations }) => {

  const handleProfileClick = (event) => {
    event.stopPropagation();
    setProfileOpen(true);
  };

  const handleOrgChange = (event) => {
    const selectedOrganization = organizations.find((org) => org.id === +event.target.value);
    setSelectedOrg(selectedOrganization);
  };

  // Generate initials from the user's first and last name
  const getInitials = (firstName, lastName) => {
    if (!firstName || !lastName) return "NA"; // Default to "NA" if names are missing
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  };

  return (
    <div className="header">
      <div className="header-left">
        <img src={logo} alt="Logo" className="header-logo" />
        <div className="header-title">Document Search</div>
      </div>
      <div className="header-right">
        {organizations.length > 0 && (
          <div className="org-dropdown-container">
            <select value={selectedOrg?.id || ""} onChange={handleOrgChange}>
              {organizations.map((org) => (
                <option key={org.id} value={org.id}>
                  {org.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="user-profile">
          <div className="profile-icon" onClick={handleProfileClick} >{getInitials(user.firstName, user.lastName)}</div>          
        </div>
      </div>
    </div>
  );
};

export default Header;
