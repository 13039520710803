import React from "react";
import "./DocumentationPage.css";

const DocumentationPage = () => {
  const documentationSections = [
    {
      title: "Overview",
      content: (
          <p>
            Document Search is an AI-driven platform that lets you easily chat with a virtual assistant about information contained in your organization's
            documents. Most users only need a few steps to get started, while organization owners have additional features related to setup and configuration.
          </p>
      ),
    },
    {
      title: "Common Features",
      content: (
        <>
          <h4>Using the Chat</h4>
          <ul>
            <li>Click "Chat" in the sidebar.</li>
            <li>Choose the data source that contains the information you want to chat about with the AI.</li>
            <li>Enter your question in the input box and hit "Send".</li>
            <li>The AI assistant will respond using any available data sources.</li>
            <li>Use the "Clear Chat" button to start a fresh conversation.</li>
          </ul>

          <h4>Switching Organizations</h4>
          <ul>
            <li>Use the organization drop-down in the top header to switch between orgs (if you belong to more than one).</li>
            <li>After switching, data sources and chat history related to that organization become available in the chat.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Org Owner Features",
      content: (
        <>
          <p>
            The features in this section are only accessible to Organization Owners or System Administrators. Owners can manage resources and user access within
            their assigned organization.
          </p>

          <h4>Managing Data Sources</h4>
          <ul>
            <li>Click "Data Sources" in the sidebar to manage existing ones.</li>
            <li>To add a new data source, use the "Add new" button and fill out the required details.</li>
            <li>You can upload files, set up GitHub repo indexing, and assign user permissions.</li>
            <li>Click "Edit" next to a data source to update or remove files, or make changes to how data is indexed.</li>
            <li>Deleting a data source permanently removes it, along with associated data.</li>
            <li>
              Additionally, any member with "Write" permission can manage the data source, such as uploading or removing files, even if they are not an Org
              Owner or System Admin.
            </li>
          </ul>

          <h4>Managing Teams</h4>
          <ul>
            <li>Click "Teams" to create or modify teams within your organization.</li>
            <li>Add users to a team to grant them shared access to certain data sources.</li>
            <li>Team membership is a convenient way to give multiple people the same permissions at once.</li>
          </ul>
        </>
      ),
    },
  ];

  return (
    <div className="documentation-page">
      <h1>Documentation</h1>
      {documentationSections.map((section, index) => (
        <div key={index}>
          <h2>{section.title}</h2>
          <div>{section.content}</div>
        </div>
      ))}
    </div>
  );
};

export default DocumentationPage;
