// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  background-color: #faf8f2;
  color: #333;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-logo {
  height: 40px;
  margin-right: 10px;
}

.header-title {
  font-size: 20px;
  font-weight: bold;
  color: #002677;
}

.header-right {
  display: flex;
  align-items: center;
}

.user-profile {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.profile-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #002677;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.org-dropdown-container {
  margin-right: 15px;
  display: flex;
  flex-direction: column;
}

.org-dropdown-container select {
  padding: 5px;
  border-radius: 10px;
  border: 1px solid #ddd;
  height: 50px;
  font-size: 16px;
  color: #002677;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,sBAAsB;EACtB,YAAY;EACZ,eAAe;EACf,cAAc;EACd,WAAW;AACb","sourcesContent":[".header {\r\n  background-color: #faf8f2;\r\n  color: #333;\r\n  padding: 10px 20px;\r\n  display: flex;\r\n  justify-content: space-between;\r\n  align-items: center;\r\n  height: 60px;\r\n}\r\n\r\n.header-left {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.header-logo {\r\n  height: 40px;\r\n  margin-right: 10px;\r\n}\r\n\r\n.header-title {\r\n  font-size: 20px;\r\n  font-weight: bold;\r\n  color: #002677;\r\n}\r\n\r\n.header-right {\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.user-profile {\r\n  position: relative;\r\n  cursor: pointer;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.profile-icon {\r\n  width: 35px;\r\n  height: 35px;\r\n  border-radius: 50%;\r\n  background-color: #002677;\r\n  color: white;\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  font-size: 16px;\r\n  font-weight: bold;\r\n}\r\n\r\n.org-dropdown-container {\r\n  margin-right: 15px;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.org-dropdown-container select {\r\n  padding: 5px;\r\n  border-radius: 10px;\r\n  border: 1px solid #ddd;\r\n  height: 50px;\r\n  font-size: 16px;\r\n  color: #002677;\r\n  width: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
