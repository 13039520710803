import React from "react";
import { FaTimes } from "react-icons/fa";
import "./ProfilePopup.css";

const ProfilePopup = ({ user, onClose }) => {
  const getInitials = (first, last) => {
    if (!first || !last) return "NA";
    return `${first.charAt(0)}${last.charAt(0)}`.toUpperCase();
  };

  return (
    <div className="popup-overlay">
      <div className="profile-popup-content">
          <h2>Profile</h2>
        <button className="close-button" onClick={onClose}>
          <FaTimes />
        </button>
        <div className="profile-popup">
          <div className="profile-header">
            <div className="avatar">{getInitials(user.firstName, user.lastName)}</div>
            <div className="profile-info">
              <h2>
                {user.lastName}, {user.firstName}
              </h2>
              <div className="email">{user.username}</div>
            </div>
          </div>

          <div className="permissions-section">
            <h3>Permissions</h3>
            <table className="permissions-table">
              <thead>
                <tr>
                  <th>Organization</th>
                  <th>Role</th>
                  <th>Teams</th>
                  <th>Data Sources</th>
                </tr>
              </thead>
              <tbody>
                {user.organizations.map((org) => (
                  <tr key={org.id}>
                    <td>{org.name}</td>
                    <td>{org.role}</td>
                    <td>{org.teams.map((team) => team.name).join(", ")}</td>
                    <td>{org.dataSources.map((ds) => `${ds.dataSourceName} (${ds.permission})`).join(", ")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePopup;
