import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ChatContentPage from "../../pages/Chat/ChatContentPage";
import DataSourcesContentPage from "../../pages/DataSources/DataSourcesContentPage";
import DataSourceFormPage from "../../pages/DataSources/DataSourceFormPage";
import TeamsContentPage from "../../pages/Teams/TeamsContentPage";
import TeamFormPage from "../../pages/Teams/TeamFormPage";
import UsersContentPage from "../../pages/Users/UsersContentPage";
import UserFormPage from "../../pages/Users/UserFormPage";
import OrganizationsContentPage from "../../pages/Organizations/OrganizationsContentPage";
import OrganizationFormPage from "../../pages/Organizations/OrganizationFormPage";
import ProfilePopup from "../../components/Profile/ProfilePopup";
import DocumentationPage from "../../pages/Resources/DocumentationPage";

const AppRoutes = ({ user, dataSources, selectedOrg, setUser }) => {
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const handleProfileClose = () => {
    setIsProfileOpen(false);
  };

  return (
    <>
      <Routes>
        {/* Removed all routes referencing old LoginPage */}
        <Route exact path="/" element={<Navigate to="/chat" />} />
        <Route
          path="/chat"
          element={
            user ? (
              <ChatContentPage
                dataSources={dataSources}
                selectedOrg={selectedOrg}
                user={user}
              />
            ) : (
              /* If user not found, could redirect to /error or show fallback */
              <Navigate to="/error" />
            )
          }
        />
        {user.isSystemAdmin ? (
          <>
            <Route
              path="/users"
              element={<UsersContentPage selectedOrg={selectedOrg} />}
            />
            <Route
              path="/users/new"
              element={<UserFormPage user={user} selectedOrg={selectedOrg} />}
            />
            <Route
              path="/users/edit/:username"
              element={<UserFormPage user={user} selectedOrg={selectedOrg} />}
            />
            <Route
              path="/organizations"
              element={<OrganizationsContentPage selectedOrg={selectedOrg} />}
            />
            <Route
              path="/organizations/new"
              element={
                <OrganizationFormPage user={user} selectedOrg={selectedOrg} />
              }
            />
            <Route
              path="/organizations/edit/:id"
              element={
                <OrganizationFormPage user={user} selectedOrg={selectedOrg} />
              }
            />
          </>
        ) : (
          <Route path="*" element={<Navigate to="/" />} />
        )}
        {(user.isSystemAdmin || user.isOrganizationOwner) && (
          <>
            <Route
              path="/teams"
              element={<TeamsContentPage selectedOrg={selectedOrg} />}
            />
            <Route
              path="/teams/new"
              element={<TeamFormPage user={user} selectedOrg={selectedOrg} />}
            />
            <Route
              path="/teams/edit/:id"
              element={<TeamFormPage user={user} selectedOrg={selectedOrg} />}
            />
          </>
        )}
        {(user.isSystemAdmin ||
          user.isOrganizationOwner ||
          user.canViewDataSourcesPage) && (
          <>
            <Route
              path="/datasources"
              element={
                <DataSourcesContentPage
                  dataSources={dataSources}
                  user={user}
                  selectedOrg={selectedOrg}
                />
              }
            />
            <Route
              path="/datasources/new"
              element={
                <DataSourceFormPage user={user} selectedOrg={selectedOrg} />
              }
            />
            <Route
              path="/datasources/edit/:id"
              element={
                <DataSourceFormPage user={user} selectedOrg={selectedOrg} />
              }
            />
          </>
        )}
        <Route
          path="/guide"
          element={user ? <DocumentationPage /> : <Navigate to="/error" />}
        />
        <Route
          path="/profile"
          element={
            user ? (
              <ProfilePopup user={user} onClose={handleProfileClose} />
            ) : (
              <Navigate to="/error" />
            )
          }
        />
        {/* Catch-all if nothing matches */}
        <Route path="*" element={<Navigate to="/chat" />} />
      </Routes>
      {isProfileOpen && (
        <ProfilePopup user={user} onClose={handleProfileClose} />
      )}
    </>
  );
};

export default AppRoutes;
