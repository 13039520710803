// HorizontalBarSpinner.js
import React from "react";
import "./HorizontalBarSpinner.css";

const HorizontalBarSpinner = () => {
  return (
    <div className="horizontal-bar-spinner">
      <div className="bar"></div>
    </div>
  );
};

export default HorizontalBarSpinner;
