// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HorizontalBarSpinner.css */
.horizontal-bar-spinner {
  position: relative;
  width: 200px;
  height: 10px;
  background-color: #e0e0e0;
  overflow: hidden;
}

.bar {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #002677;
  animation: bar-loading 1s infinite linear;
}

@keyframes bar-loading {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Common/HorizontalBarSpinner.css"],"names":[],"mappings":"AAAA,6BAA6B;AAC7B;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,yBAAyB;EACzB,yCAAyC;AAC3C;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF","sourcesContent":["/* HorizontalBarSpinner.css */\r\n.horizontal-bar-spinner {\r\n  position: relative;\r\n  width: 200px;\r\n  height: 10px;\r\n  background-color: #e0e0e0;\r\n  overflow: hidden;\r\n}\r\n\r\n.bar {\r\n  position: absolute;\r\n  width: 50%;\r\n  height: 100%;\r\n  background-color: #002677;\r\n  animation: bar-loading 1s infinite linear;\r\n}\r\n\r\n@keyframes bar-loading {\r\n  0% {\r\n    left: -50%;\r\n  }\r\n  100% {\r\n    left: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
