import { axiosInstance } from "./config";

export const getStoredUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const fetchUser = async (username) => {
  try {
    const response = await axiosInstance.get(`/api/user/${username}`);
    if (response.status === 204) {
      return { exists: false };
    }
    const user = response.data;
    user.isOrganizationOwner = user.organizations.some((org) => org.role === "Owner");
    user.canViewDataSourcesPage = user.organizations.some((org) => org.dataSources.some((dataSource) => dataSource.permission === "Write"));
    user.canViewSidebar = user.isSystemAdmin || user.isOrganizationOwner || user.canViewDataSourcesPage;
    return user;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error;
  }
};

export const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get(`/api/user/all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};

export const saveUser = async (user, isEdit) => {
  try {
    if (isEdit) {
      await axiosInstance.put(`/api/user`, user);
    } else {
      await axiosInstance.post(`/api/user`, user);
    }
  } catch (error) {
    console.error("Error saving user:", error);
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    await axiosInstance.delete(`/api/user/${id}`);
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
